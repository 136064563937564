<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- Striped Rows -->
    <div class="col-xxl-9">
      <div class="card mt-xxl-n5">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>

              <th scope="col">Nom</th>

              <th scope="col">Prenoms</th>

              <th scope="col">sexe</th>

              <th scope="col">Adresse mail</th>

              <th scope="col">statut</th>

              <th scope="col">Action</th>

            </tr>

          </thead>

          <tbody>

            <tr v-for="student in students" :key="student.id">

              <th scope="row">{{ student.id }}</th>

              <td>{{ student.nom }}</td>

              <td>{{ student.prenom }}</td>

              <td>{{ student.sexe }}</td>

              <td>{{ student.email }}</td>


              <td v-if="student.statuts == 'valider'"><span class="badge bg-success">{{ student.statuts }}</span>
              </td>

              <td><span class="badge bg-danger">{{ student.statuts }}</span></td>

              <td>
                <div class="hstack gap-3 flex-wrap">
                  <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModalgrid"
                    @click="getStudent(student)" class="ml-3 link-success fs-15"><i class="ri-eye-line"></i></a>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="modal fade" id="exampleModalgrid" tabindex="-1" aria-labelledby="exampleModalgridLabel"
        aria-modal="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body card">
              <div class="card-body">
                <h5 class="card-title mb-3">Informations Détailés</h5>
                <div class="table-responsive">
                  <table class="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <th class="ps-0" scope="row">Numero matricule :</th>
                        <td class="text-muted">{{ this.student?.matricule }}</td>
                      </tr>
                      <tr>
                        <th class="ps-0" scope="row">Nom complet :</th>
                        <td class="text-muted">{{ this.student?.user?.name }}</td>
                      </tr>
                      <tr>
                        <th class="ps-0" scope="row">Sexe :</th>
                        <td class="text-muted" v-if="this.student?.user?.genre == 'Homme'">Masculin</td>
                        <td class="text-muted" v-if="this.student?.user?.genre == 'Femme'">Feminin</td>
                      </tr>
                      <tr>
                        <th class="ps-0" scope="row">Numero de téléphone :</th>
                        <td class="text-muted">{{ this.student?.user?.number }}</td>
                      </tr>
                      <tr>
                        <th class="ps-0" scope="row">E-mail :</th>
                        <td class="text-muted">{{ this.student?.user?.email }}</td>
                      </tr>
                      <tr>
                        <th class="ps-0" scope="row">Adresse :</th>
                        <td class="text-muted">{{ this.student?.user?.address }}</td>
                      </tr>
                      <tr v-if="this.student?.user?.etat == 'pending'">
                        <th class="ps-0" scope="row">Lien de confirmation:</th>
                        <td class="text-muted">http://localhost:8080/account-confirm/{{ this.student?.user?.slug }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </Layout>
</template>

<style scoped>
.ml-3 {
  margin-left: 18px;
}
</style>
  
<script>

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";

export default {
  setup() { },

  data() {
    return {
      data: {},
      students: {},
      student: {},
      title: "Liste",
      items: [
        {
          text: "Etudiants",
          href: "/",
        },
        {
          text: "Tout",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.getAllStudents();
  },

  methods: {
    getStudent(std) {
      this.student = std;
    },
    getAllStudents() {
      const self = this;
      this.$store.dispatch('getRequest', { route: 'api/user/etudiant ', data: this.data })
        .then(function (response) {
          self.students = response.data.searchedUsers;
        },
          function (error) {
            console.log(error);
          }
        )
    },

  },
};
</script>
  
  